/**
 * Copy given value to clipboard.
 *
 * @param {string} value Value to copy.
 * @param {root?} root The root to use to create the temporary input that will be used to make a copy, defaults to `document.body`.
 * @return {void}
 */
export function copyToClipboard(value, root) {
    const temporaryRoot = root || document.body;
    const temporaryTextarea = document.createElement('textarea');
    const isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);

    temporaryTextarea.value = value;
    temporaryTextarea.setAttribute('readonly', '');

    temporaryTextarea.style.position = 'absolute';
    temporaryTextarea.style.left = '-9999px';
    temporaryTextarea.style.zIndex = '2000';
    temporaryRoot.appendChild(temporaryTextarea);

    if (isiOSDevice) {
        // select() doesn't works on iOS
        temporaryTextarea.contentEditable = true;
        temporaryTextarea.readOnly = false;

        const range = document.createRange();
        range.selectNodeContents(temporaryTextarea);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        temporaryTextarea.setSelectionRange(0, 999999);
    } else {
        temporaryTextarea.select();
    }

    try {
        document.execCommand('copy');
    } finally {
        temporaryRoot.removeChild(temporaryTextarea);
    }
}
