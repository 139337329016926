import {createEventBus} from '@malt/vue-event-bus/create-event-bus';
import type {EditionOpenedEntryPoint} from '~/config/TrackingEvents';

// TODO: We should update the typing of Events
// TODO: Remove this once the types of Events updated
// For the time being, created this enum to avoid typing errors
export enum ProfileEdtionDataUpdatedEvents {
    description_updated = 'description:updated',
    educations_updated = 'educations:updated',
    experiences_updated = 'experiences:updated',
    expertises_skills_strategy_updated = 'expertises-skills-strategy:updated',
    expertises_skills_updated = 'expertises-skills:updated',
    external_action_recommendations_added = 'external-action:recommendations:added',
    industry_expertise_updated = 'industry-expertise:updated',
    languages_updated = 'languages:updated',
    photo_updated = 'photo:updated',
    profile_location_preferences_updated = 'profile-location-preferences:updated',
    recommendations_updated = 'recommendations:updated',
    skill_set_updated = 'skill-set:updated',
}

// TODO
export enum ProfileEdtionDataDeletedEvents {
    educations_deleted = 'educations:deleted',
}

// TODO: The return type of events should be void, instead of undefined.
// TODO: Use enum for better typing
export type EditModeEvents = {
    'admin-panel:close': undefined;
    'admin-panel:open': undefined;
    'appraisals:updated': undefined;
    'breadcrumb:edit-mode': undefined;
    'breadcrumb:updated': undefined;
    'categories:edit-mode': {entryPoint: EditionOpenedEntryPoint};
    'categories:updated': undefined;
    'certifications:edit-mode': {certificationId: number | undefined; entryPoint: EditionOpenedEntryPoint};
    'certifications:updated': undefined;
    'description:edit-mode': {entryPoint: EditionOpenedEntryPoint};
    'description:updated': undefined;
    'dev-tools:edit-mode': undefined;
    'educations:add-mode': {entryPoint: EditionOpenedEntryPoint};
    'educations:edit-mode': {educationId: number; entryPoint: EditionOpenedEntryPoint};
    'educations:deleted': void;
    'educations:updated': undefined;
    'experience-level:edit-mode': {entryPoint: EditionOpenedEntryPoint};
    'experience-level:updated': undefined;
    'experiences:add-mode': {entryPoint: EditionOpenedEntryPoint};
    'experiences:cancel': undefined;
    'experiences:edit-mode': {experienceId: number; entryPoint: EditionOpenedEntryPoint};
    'experiences:resume-import-mode': {entryPoint: EditionOpenedEntryPoint};
    'experiences:cancel-import-hotjar-survey': undefined;
    'experiences:selection-mode': undefined;
    'experiences:updated': undefined;
    'expertises-skills-strategy:updated': void;
    'expertises-skills:edit-mode': undefined;
    'expertises-skills:rate-mode': undefined;
    'expertises-skills:updated': void;
    'headline:edit-mode': {entryPoint: EditionOpenedEntryPoint};
    'headline:updated': undefined;
    'industry-expertise:edit-mode': {entryPoint: EditionOpenedEntryPoint};
    'industry-expertise:updated': undefined;
    'languages:edit-mode': {entryPoint: EditionOpenedEntryPoint};
    'languages:updated': undefined;
    'location-preferences:edit-mode': {entryPoint: EditionOpenedEntryPoint};
    'mission-preferences:updated': undefined;
    'photo:edit-mode': undefined;
    'photo:updated': undefined;
    'price:edit-mode': {entryPoint: EditionOpenedEntryPoint};
    'price:updated': undefined;
    'profile-location-preferences:updated': undefined;
    'profile:updated': undefined;
    'recommendations:edit-mode': undefined;
    'recommendations:updated': undefined;
    'reputation:edit-mode': {entryPoint: EditionOpenedEntryPoint};
    'reputation:updated': undefined;
    'resume-import:updated': undefined;
    'skill-set:edit-mode': {entryPoint: EditionOpenedEntryPoint};
    'skill-set:updated': void;
    'social-share-seo:edit-mode': undefined;
    'social-share-seo:updated': undefined;
    'task-card-tags-suggestion:added': void;
};

type ExternalUserActionEvents = {
    'external-action:recommendations:add': void | {experienceId: string};
    'external-action:recommendations:added': undefined;
    'external-action:recommendations:ready': void;
    'onboarding:closed': undefined;
    'shortlist:display-choice': undefined;
    'translation:request': undefined;
    'translation:restore': undefined;
    'wishlist:add-mode': undefined;
    'wishlist:added': undefined;
    'wishlist:edit-mode': undefined;
};

type MainComponentEvents = {
    'main:recommendations:ready': void;
    'main:recommendations:scroll-to': void;
};

type VettingEvents = {
    'verticalAcceptToRegular:open': any;
    'verticalRefuseSilentlyToStrategy:open': any;
    'verticalRefuseToStrategy:open': any;
    'verticalSuggestToStrategy:open': string[];
};

export type Events = EditModeEvents & ExternalUserActionEvents & MainComponentEvents & VettingEvents;

export const eventBus = createEventBus<Events>();
